<template>
  <div class="pages">
    <Header :type="$route.query.type" />
    <div class="pay-info">
      <img src="@/assets/mine/success.png" />
      <div class="title">支付成功
        <!-- {{ isSuccess ? "支付成功" : "支付失败" }} -->
      </div>
      <div>微信付款${{ $route.query.price }}</div>

      <div class="btn" @click="goMyOrder">查看订单</div>
    </div>
  </div>
</template>
<script>
import { getOrderState } from "@/api/pay.js";
export default {
  data() {
    return {
      isSuccess: false,
    };
  },
  methods: {
    // 完成支付查询跳转
    ppfDetails() {
      getOrderState({
        orderNumber: this.$router.query.orderNumber,
      }).then((res) => {
        if (res) {
          if (res.data.state !== 0) {
            this.isSuccess = true;
          }
        } else {
          this.isSuccess = false;
        }
      });
    },
    goMyOrder() {
      // this.$router.push({
      //   url: "https://swa.org.hk/#/mine",
      //   query: {
      //     activeIndex: 0,
      //   },
      // });
      location.href = "https://swa.org.hk/#/mine";
    },
  },
};
</script>

<style lang="less" scoped>
.pages {
  background: #f5f8fa;
}
.pay-info {
  width: 1200px;
  height: 720px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  margin: 20px auto 80px;
  text-align: center;
  font-size: 16px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #999999;
  img {
    width: 68px;
    height: 68px;
    margin: 40px 0;
  }
  .title {
    font-size: 22px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #444444;
    margin-bottom: 20px;
  }
  .btn {
    width: 166px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background: #ffffff;
    border-radius: 28px 28px 28px 28px;
    opacity: 1;
    border: 1px solid #cccccc;
    margin: 30px auto;
  }
}
</style>
